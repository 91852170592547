body{
    font-family: 'Open Sans';
}

.contacts{
    margin-bottom: 20px;
}

.fade-line{
    display:block;
    height:1px;
    background: linear-gradient(90deg, rgba(122, 122, 122, 0.315) 150px, rgba(170,170,170,0.315) 200px, rgba(0,212,255,0) 350px);
    margin: 10px 0px;
}

.caption{
    display: none;
}

.purple-border{
    border: purple solid 1px;
}
.red-border{
    border: red solid 1px;
}
.blue-border{
    border: blue solid 1px;
}
.green-border{
    border: green solid 1px;
}
.orange-border{
    border: orange solid 1px;
}

.video-slide-container{
    .video-slide-wrapper{
        .video-slide{
            video{
                width: 100%;
                height: auto;
            }
            img{
                width: 100%;
                height: auto;
                max-height: 720px;
            }
        }
    }
}

.video-slide-nav-container {
    .video-slide-nav-wrapper{
        overflow: hidden; 
        max-height: 200px;
        .video-slide-nav{
            padding: 10px;
            img{
                max-width: 100vw;
                width: 100%;
                height: 200px;
            }
            video{
                width: 100%;
                height: 195px;
                object-fit: cover;
            }
        }
    }
}

.slick-slide img{
    width: 100%;
}

.video-player-strip{
    width: 100%;
}

.video-slide{
    padding: 10px;
}
.pagination{
    ul{
        margin: 10px auto 35px auto;
        list-style-type: none;
        padding: 0px;
        display: flex;
        flex-flow: row nowrap;

        li{
            margin: 0px 5px;
            
            a{
                color: @loynes-primary;
                font-weight: bold;
                line-height: 1rem;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                p{
                    text-align: center;
                    line-height: inherit;
                    font-size: 1rem;
                    margin: 0px;
                }
                ion-icon{
                    line-height: inherit;
                    font-size: 1rem;
                }
            }

            &.active-page{
                text-decoration: underline;
            }

            &.pagination-number{
                padding: 10px 0px;
            }
        }
    }
}    

.pagination-button{
    border: solid 2px @loynes-primary;
    color: @loynes-primary;
    padding: 10px 25px;
    font-weight: bold;
    margin-bottom: 15px;
    box-sizing: border-box;
    width: 135px;
    &:hover{
        color: inherit;
        text-decoration: none;
    }
}


footer{
    text-align: center;
    .footer-wrapper{
        background-color: #F7F7F7;        ;
    }
    .footer-img{
        margin: auto;
        width: inherit;
        max-width: 430px;
    }
    .useful-resources{
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
        text-align: left;
        padding: 35px 0px;
        .useful-resources-list{
            margin: 25px 0px 0px 0px;
            list-style-type: none;
            padding-left: 0px;
            .useful-resources-list-item{
                text-decoration: underline;
                margin: 5px 0px;
                a{
                    color: black;
                }
            }
        }
    }

    .footer-top{
        margin: 20px 0px;
        .footer-partners{
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
            margin: auto;
            max-width: 860px;

            img{
                height: 80px;
            }
        }
    }

    .footer-bottom{
        width: 100%;
        background-color: #19868a;
        .footer-bottom-content{
            min-height: 4rem;
            height: 4rem;
            margin: auto;   
            color: white;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-content: center;
            font-size: .8rem;
            max-width: 1200px;
            .footer-links, .footer-contacts{
                display: flex;
                flex-flow: row wrap;
                align-self: center;
                a{
                    color: white;
                    display: block;
                    text-decoration: underline;
                    margin: 0px .5rem;
                    line-height: .8rem;
                    .footer-contacts{
                        text-decoration: none;
                    }
                    
                    ion-icon{
                        font-size: larger;
                    }
                }
            }
            
            .footer-links{
                justify-content: flex-start;
                line-height: 19.2px;
                p, a{
                    margin: 0 0 0 1rem;
                    line-height: 19.2px;
                }
                @media screen and (max-width: 768px){
                    justify-content: center;
                }
            }
            
            .footer-contacts{
                justify-content: flex-end;
                align-content: center;
                line-height: 19.2px;
                a{
                    display: flex;
                    flex-flow: row wrap;
                    align-content: center;
                    line-height: 19.2px;
                    text-decoration: none;
                    ion-icon{
                        font-size: 19.2px;
                        margin-right: 3px;
                        line-height: 19.2px;
                    }
                    p{
                        line-height: 19.2px !important;
                        margin: 0;
                    }
                }
                
                .footer-contact-info{
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    @media screen and (max-width: 768px){
                        justify-content: center;
                    }
                }
                
                .social-media-icons{
                    display: flex;
                    flex-flow: row wrap;
                    a{
                        margin: 3px;
                    }
                }
                @media screen and (max-width: 768px){
                    justify-content: center;
                }
            }
            
            @media screen and (max-width: 1024px){
                justify-content: center;
            }
        }
    }
}

#articles{
    display: flex;
    flex-flow: row wrap;
    padding: 0px;

    h1, h2, h3, h4{
        width: 100%;
        margin-bottom: 25px;
    }

    .articles-tile{
        width: 32%;
        margin-bottom: 25px;
        img{
            width: 100%;
            height: 262.5px;
            object-fit: cover;
        }
        .article-content{
            border: solid 1px rgba(202, 202, 202, 0.363);
            border-radius: 0px 0px 0px 10px;
            border-top: none;
            padding: 15px;

            .article-heading{
                font-size: 1.5em;
                min-height: 4.8rem;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .article-description{
                width: 100%;
                display: block;
                margin: 10px 0px 25px 0px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            a{
                color: @loynes-primary;
                text-decoration: underline;
            }
        }
    }
}

.slick-prev, .slick-next, .slick-arrow{
    color: black !important;
}
.slick-next:before, .slick-prev:before{
    color: black !important;
    font-size: 3rem;
}
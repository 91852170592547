// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

#button-variant(@background, @border, @hover-background: darken(@background, 7.5%), @hover-border: darken(@border, 10%), @active-background: darken(@background, 10%), @active-border: darken(@border, 12.5%)) {
	color: color-yiq(@background);
	#gradient-bg(@background);
	border-color: @border;
	#box-shadow(@btn-box-shadow);

	#hover({
		color: color-yiq(@hover-background);
		#gradient-bg(@hover-background);
		border-color: @hover-border;
	});

	&:focus,
	&.focus {
		// Avoid using mixin so we can pass custom focus shadow properly
		& when (@enable-shadows) {
			box-shadow: @btn-box-shadow, 0 0 0 @btn-focus-width fade(mix(color-yiq(@background), @border, 15%), 50%);
		}
		& when not (@enable-shadows) {
			box-shadow: 0 0 0 @btn-focus-width fade(mix(color-yiq(@background), @border, 15%), 50%);
		}
	}

	// Disabled comes first so active can properly restyle
	&.disabled,
	&:disabled {
		color: color-yiq(@background);
		background-color: @background;
		border-color: @border;
		// Remove CSS gradients if they're enabled
		& when (@enable-gradients) {
			background-image: none;
		}
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
		color: color-yiq(@active-background);
		background-color: @active-background;
		& when (@enable-gradients) {
			background-image: none; // Remove the gradient for the pressed/active state
		}
		border-color: @active-border;

		&:focus {
			// Avoid using mixin so we can pass custom focus shadow properly
			& when (@enable-shadows) and not (@btn-active-box-shadow = none) {
				box-shadow: @btn-active-box-shadow, 0 0 0 @btn-focus-width fade(mix(color-yiq(@background), @border, 15%), 50%);
			}
			& when not (@enable-shadows) or (@btn-active-box-shadow = none) {
				box-shadow: 0 0 0 @btn-focus-width fade(mix(color-yiq(@background), @border, 15%), 50%);
			}
		}
	}
}

#button-outline-variant(@color, @color-hover: color-yiq(@color), @active-background: @color, @active-border: @color) {
	color: @color;
	border-color: @color;

	#hover({
		color: @color-hover;
		background-color: @active-background;
		border-color: @active-border;
	});

	&:focus,
	&.focus {
		box-shadow: 0 0 0 @btn-focus-width fade(@color, 50%);
	}

	&.disabled,
	&:disabled {
		color: @color;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
		color: color-yiq(@active-background);
		background-color: @active-background;
		border-color: @active-border;

		&:focus {
			// Avoid using mixin so we can pass custom focus shadow properly
			& when (@enable-shadows) and not (@btn-active-box-shadow = none) {
				box-shadow: @btn-active-box-shadow, 0 0 0 @btn-focus-width fade(@color, 50%);
			}
			& when not (@enable-shadows) or (@btn-active-box-shadow = none) {
				box-shadow: 0 0 0 @btn-focus-width fade(@color, 50%);
			}
		}
	}
}

// Button sizes
#button-size(@padding-y, @padding-x, @font-size, @line-height, @border-radius) {
	padding: @padding-y @padding-x;
	#font-size(@font-size);
	line-height: @line-height;
	// Manually declare to provide an override to the browser default
	#border-radius(@border-radius, 0);
}

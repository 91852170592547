.project-specialist-content{
    .project-specialist-header{
        h1{
            
        }
        
        h2, h3, h4{
            font-size: 1rem;
        }
    }

    .project-specialist-image{
        img{
            object-fit: cover;
            width: 100%;
            height: 300px;
            margin: 10px 0px 20px 0px;
        }
    }

    .project-specialist-description{
        
    }

    .project-specialist-contact-button{
        margin: 30px 0px;
        .blue-link-button{
            background-color: white;
            border: solid 2px @loynes-primary;
            color:  @loynes-primary;
            &:hover{
                background-color: @loynes-primary;
                border: solid 2px @loynes-primary;
                color:  white;
                transition: 0.2s all;
            }
        }    
    }

    .success-stories{
        margin-top: 70px;
        h3{
            margin-bottom: 15px;
        }

        ul{
            list-style-type: none;
            padding: 0px;

            li{
                text-decoration: underline;
                line-height: 2rem;
            }
        }
    }
}

.sidebar-links{
    
    h3{
        font-size: 1.4rem;
        color: @loynes-primary;
    }

    h4{
        font-size: .8rem;
        color: @loynes-primary;
    }

    ul{
        padding: 0px;
        list-style-type: none;
        margin-bottom: 25px;
        
        li{
            font-size: .8rem;
            margin: 5px 0px;
            a {
                font-size: 16px;
                color: black;
                &:hover {
                    text-shadow:0px 0px 1px @loynes-primary;
                    color: @loynes-primary;
                }
            }
        }
    }

}
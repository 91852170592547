.toast {
	max-width: @toast-max-width;
	overflow: hidden; // cheap rounded corners on nested items
	#font-size(@toast-font-size);
	// LESS PORT: Less doesn’t strip “empty” property values so we have to check for a value first.
	& when not (@toast-color = ~"") { color: @toast-color; }
	background-color: @toast-background-color;
	background-clip: padding-box;
	border: @toast-border-width solid @toast-border-color;
	box-shadow: @toast-box-shadow;
	backdrop-filter: blur(10px);
	opacity: 0;
	#border-radius(@toast-border-radius);

	&:not(:last-child) {
		margin-bottom: @toast-padding-x;
	}

	&.showing {
		opacity: 1;
	}

	&.show {
		display: block;
		opacity: 1;
	}

	&.hide {
		display: none;
	}
}

.toast-header {
	display: flex;
	align-items: center;
	padding: @toast-padding-y @toast-padding-x;
	color: @toast-header-color;
	background-color: @toast-header-background-color;
	background-clip: padding-box;
	border-bottom: @toast-border-width solid @toast-header-border-color;
}

.toast-body {
	padding: @toast-padding-x; // apply to both vertical and horizontal
}

/*!
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

html {
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

@import "bootstrap/_functions";
@import "bootstrap/_variables";

@import "bootstrap/mixins/_breakpoints";
@import "bootstrap/mixins/_grid-framework";
@import "bootstrap/mixins/_grid";

@import "bootstrap/_grid";
@import "bootstrap/utilities/_display";
@import "bootstrap/utilities/_flex";
@import "bootstrap/utilities/_spacing";

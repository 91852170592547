.contact-form{
    
    h2, h3, h4{
        margin-bottom: 2rem;
    }
    .formulate__field {
        color: @loynes-primary;
        text-transform: uppercase;
        font-weight: bold;
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
        grid-template-areas: 
            "label"
            "input"
            "validation";
        margin-bottom: 15px;

        .formulate__inline-validation-summary{
            font-style: italic;
            color: red;
            font-weight: normal;
            font-size: .9rem;
            list-style-type: none;
            margin: 0px;
            padding: 0px;
            li{
                margin: none;
            }
        }

        .formulate__field__label{
            width: 100%;
        }
        input, textarea{
            width: 100%;
        }
    }

    input{
        border: solid 1px @loynes-primary;
        padding: 10px 15px;
    }

    textarea{
        border: solid 1px @loynes-primary;
        padding: 10px 15px;
    }

    button{
        border: solid 1px @loynes-primary;
        background-color: transparent;
        color: @loynes-primary;
        text-transform: uppercase;
        font-weight: bold;
        padding: 7.5px 0px;
        width: 100%;
        &:hover{
            background-color: @loynes-primary;
            color: white;
            transition: 0.2s all;
        }
    }

    .formulate__validation-summary{
        display: none;
    }
}
header{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0px 50px;
    max-width: 1200px;
    margin: auto;

    .logo{

        .logo-image{
            max-width: 350px;
        }
    }
}


@media(max-width: 992px){
    header{
        justify-content: center;
    }

    main {
        // padding-top: 30px;
    }
}
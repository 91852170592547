.links{
    margin: 30px 0px;
    @media screen and (max-width: 768px){
        text-align: center;
    }
}
.blue-link-button{
    color: white;
    background-color: @loynes-primary;
    padding: 10px 30px;
    margin: 5px;
    border-radius: 0px 5px 0px 5px;
    text-decoration: none;
    display: inline-block;
    :hover{
        text-decoration: none;
    }

    @media screen and (max-width: 425px){
        width: 45%;
    }
    @media screen and (max-width: 320px){
        width: 100%;
    }
}

.nav-bar {
    flex-flow: row wrap;
    justify-content: flex-end;
    width: calc(100% - 370px);
    color: @loynes-primary;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 20px;
    @media(min-width: 992px) {
        display: flex;
    }

    .navbar-nav {
        flex-flow: row nowrap;
    }

    .navbar {
        padding: 0;
        margin: 15px 0;

        @media(min-width: 992px) {
            margin: 15px 0 0;
        }
    }

    a {
        color: @loynes-primary;
    }

    .main-menu,
    .secondary-menu {
        flex-flow: row nowrap;
        width: 100%;
        justify-content: flex-end;

        @media(min-width: 992px) {
            display: flex;
        }
    }

    .main-menu {
        align-items: flex-start;

        a {
            align-self: flex-start;
            font-weight: bolder;
        }

        .nav-item {
            margin: 0 0 0 15px;

            a {
                color: @loynes-primary;
                padding-top: 0;

                a {
                    color: @loynes-primary;
                }
            }

            .nav-link {
                padding: 0;

                @media(max-width: 991px) {
                    color: white;
                }
            }

            .dropdown-menu {
                display: block;
                padding: 0;
                transition: .5s;
                transform: scaleY(0);
                transform-origin: top;

                @media(min-width: 992px) {
                    display: block;
                }

                .sub-link {
                    padding: 5px 10px;
                    color: @loynes-primary;
                    font-weight: normal;
                }

                .sub-link-category {
                    padding: 5px 10px;
                    color: @loynes-primary;
                    font-weight: bold;
                    margin: 8px 0px 0px 0px;
                }

                @media(min-width: 992px) {

                    &.show {
                        transform: scaleY(1);
    
                        .sub-link {
                            display: block;
                            transform: scaleY(1);
                        }
                    }
                }

                @media(max-width: 992px) {
                    .sub-link {
                        display: none;
                        transform: scaleY(0);
                        transition: .5s;
                    }

                    &.show-menu {
                        transform: scaleY(1);
    
                        .sub-link {
                            display: block;
                            transform: scaleY(1);
                        }
                    }
                }
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
            z-index: 1;
            padding: 0;

            .sub-link {
                font-size: 1rem;
                display: block;
                padding: 5px 10px;
            }
        }

        &:hover {
            .sub-menu {
                display: block;
            }
        }
    }

    .secondary-menu {
        a {
            font-size: 0.65em;
            font-weight: bold;
            align-self: flex-end;
        }

        :last-child {
            padding-right: 0;
        }
    }

    .nav-link::after,
    .dropdown-toggle::after {
        content: "";
        border: none;
    }
}

.navbar-toggler {
    display: none;
}

.navbar-toggler-icon {
    color: white;
}

@media screen and(max-width: 992px) {
    .navbar-toggler {
        display: inline-block;
    }

    .main-menu,
    .secondary-menu {

        // transform: scaleY(0);
        .collapse {
            margin: auto;
        }
    }

    .nav-bar {
        width: 100%;
        text-align: center;
        background-color: @loynes-primary;
        justify-content: center;
        align-content: center;
        margin-right: 0;

        a {
            width: 100%;
            color: #fff;
            text-align: center;
            font-size: 16px !important;
            padding: 5px 20px !important;
        }
    }

    .navbar,
    .secondary-menu {
        display: none;
    }

    .nav-bar .top-nav {
        flex-flow: column;
        text-align: center;
        justify-content: center;
    }

    .nav-bar .navbar-nav,
    .secondary-menu {
        justify-content: center;
        flex-flow: column nowrap;
    }

    .nav-bar .main-menu,
    .nav-bar .secondary-menu {
        justify-content: center;
    }

    .nav-item {
        text-align: center;
        justify-self: center;
        padding: 0 !important;
        margin: 0 !important;
    }

    .collapse {
        width: 100%;
        ul {
            width: 100%;
            li {
                width: 100%;
            }
        }
    }

    .nav-bar .secondary-menu:last-child {
        padding-right: 1rem !important;
    }

    .nav-bar .main-menu,
    .nav-bar .secondary-menu {
        flex-flow: column nowrap;
        justify-content: center;

        .nav-link {
            align-self: center;
        }
    }

    .navbar-toggler {
        outline: 0 !important;
        border: 0 !important;
    }
}


.header-contacts {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-end;
    line-height: 19.2px;
    font-size: 0.9rem;
    a {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        line-height: 19.2px;
        text-decoration: none;
        color: #414344;
        ion-icon {
            color: #65696b;
            font-size: 19.2px;
            margin-right: 3px;
            line-height: 19.2px;
        }
        p {
            line-height: 19.2px !important;
            margin: 0;
        }
    }

    .header-contact-info {
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        a {
            width: 100%;
            p {
                text-align: right;
            }
        }
    }

    .social-media-icons {
        display: flex;
        flex-flow: row wrap;
        a {
            margin: 3px;
        }
    }
    @media(max-width: 992px) {
        display: none;
    }
}

@media(min-width: 992px) {
    .dropdown-toggle-mobile {
        display: none;
    }
}

@media(max-width: 991px) {
    .navbar-nav {
        .nav-item {
            position: relative;

            .dropdown-toggle-mobile {
                position: absolute;
                padding: 10px 25px 0 25px; 
                top: 0;
                right: 0;

                ion-icon {
                    color: white;
                }
            }
        }
    }
}
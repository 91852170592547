.umb-grid{
    .grid-section{
        .container{
            .row{
                .column{
                    padding: 0;
                }
            }
        }
    }
}
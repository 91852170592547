    
a {
    &:hover {
        text-decoration: none;
    }
}

.back-button{
    border: solid 2px @loynes-primary;
    color: @loynes-primary;
    padding: 10px 25px;
    font-weight: bold;
    margin-bottom: 15px;
    box-sizing: border-box;
    &:hover{
        color: inherit;
        text-decoration: none;
    }
}

.blue-link-button {
    &:hover {
        color: #fff;
    }
}
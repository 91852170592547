
//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin-bottom: @headings-margin-bottom;
	// LESS PORT: Less doesn’t strip “empty” property values so we have to check for a value first.
	& when not (@headings-font-family = ~"") { font-family: @headings-font-family; }
	font-weight: @headings-font-weight;
	line-height: @headings-line-height;
	// LESS PORT: Less doesn’t strip “empty” property values so we have to check for a value first.
	& when not (@headings-color = ~"") { color: @headings-color; }
}

h1, .h1 { #font-size(@h1-font-size); }
h2, .h2 { #font-size(@h2-font-size); }
h3, .h3 { #font-size(@h3-font-size); }
h4, .h4 { #font-size(@h4-font-size); }
h5, .h5 { #font-size(@h5-font-size); }
h6, .h6 { #font-size(@h6-font-size); }

.lead {
	#font-size(@lead-font-size);
	font-weight: @lead-font-weight;
}

// Type display classes
.display-1 {
	#font-size(@display1-size);
	font-weight: @display1-weight;
	line-height: @display-line-height;
}
.display-2 {
	#font-size(@display2-size);
	font-weight: @display2-weight;
	line-height: @display-line-height;
}
.display-3 {
	#font-size(@display3-size);
	font-weight: @display3-weight;
	line-height: @display-line-height;
}
.display-4 {
	#font-size(@display4-size);
	font-weight: @display4-weight;
	line-height: @display-line-height;
}


//
// Horizontal rules
//

hr {
	margin-top: @hr-margin-y;
	margin-bottom: @hr-margin-y;
	border: 0;
	border-top: @hr-border-width solid @hr-border-color;
}


//
// Emphasis
//

small,
.small {
	#font-size(@small-font-size);
	font-weight: @font-weight-normal;
}

mark,
.mark {
	padding: @mark-padding;
	background-color: @mark-bg;
}


//
// Lists
//

.list-unstyled {
	#list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
	#list-unstyled();
}
.list-inline-item {
	display: inline-block;

	&:not(:last-child) {
		margin-right: @list-inline-padding;
	}
}


//
// Misc
//

// Builds on `abbr`
.initialism {
	#font-size(90%);
	text-transform: uppercase;
}

// Blockquotes
.blockquote {
	margin-bottom: @spacer;
	#font-size(@blockquote-font-size);
}

.blockquote-footer {
	display: block;
	#font-size(@blockquote-small-font-size);
	color: @blockquote-small-color;

	&::before {
		content: "\2014\00A0"; // em dash, nbsp
	}
}

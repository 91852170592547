// Single side border-radius

#border-radius(@radius: @border-radius, @fallback-border-radius: false) {
	& when (@enable-rounded) {
		border-radius: @radius;
	}
	& when not (@enable-rounded) and not (@fallback-border-radius = false) {
		border-radius: @fallback-border-radius;
	}
}

#border-top-radius(@radius) {
	& when (@enable-rounded) {
		border-top-left-radius: @radius;
		border-top-right-radius: @radius;
	}
}

#border-right-radius(@radius) {
	& when (@enable-rounded) {
		border-top-right-radius: @radius;
		border-bottom-right-radius: @radius;
	}
}

#border-bottom-radius(@radius) {
	& when (@enable-rounded) {
		border-bottom-right-radius: @radius;
		border-bottom-left-radius: @radius;
	}
}

#border-left-radius(@radius) {
	& when (@enable-rounded) {
		border-top-left-radius: @radius;
		border-bottom-left-radius: @radius;
	}
}

#border-top-left-radius(@radius) {
	& when (@enable-rounded) {
		border-top-left-radius: @radius;
	}
}

#border-top-right-radius(@radius) {
	& when (@enable-rounded) {
		border-top-right-radius: @radius;
	}
}

#border-bottom-right-radius(@radius) {
	& when (@enable-rounded) {
		border-bottom-right-radius: @radius;
	}
}

#border-bottom-left-radius(@radius) {
	& when (@enable-rounded) {
		border-bottom-left-radius: @radius;
	}
}

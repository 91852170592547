.recent-projects{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0px;

    h1, h2, h3, h4{
        width: 100%;

    }

    .recent-project{
        width: 32%;
        margin: 0px 0px 25px 0px;
        .recent-project-image-container{
            height: 200px;
            background-size: cover;
            background-position: top;
        }
        .recent-project-content{
            border: solid 1px rgba(202, 202, 202, 0.363);
            border-radius: 0px 0px 0px 10px;
            border-top: none;
            padding: 15px;

            .recent-project-heading{
                font-size: 1.22rem;
                height: 3rem;
            }

            .recent-project-description{

                width: 100%;
                display: block;
                margin: 10px 0px 25px 0px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            a{
                color: @loynes-primary;
                text-decoration: underline;
            }
        }
    }
}
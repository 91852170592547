#services {
    margin-bottom: 50px;

    h1 {
        margin-bottom: 30px;
    }
    h3{
        font-size: 1.27rem;
    }
    .container {
        padding: 0;
    }
}

#project-specialists {
    h3{
        font-size: 1.27rem;
    }
    h4 {
        margin-bottom: 30px;
    }

    .container {
        padding: 0;
    }
}

.services-tile {
    width: 30%;
    max-height: 450px;
    margin-bottom: 40px;
    position: relative;
    
    h1,h2,h3,h4,h5{
        font-weight: bold;
        height: 2.5rem;
        margin: 0px 0px 15px 0px;

    }

    .services-tile-image {
        width: 100%;
        height: 200px;
    }
    .services-tile-content {
        height: 95px;
        span {
            // color: white;
            width: 100%;
            display: block;
            margin: 10px 0px 25px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
    a {
        background-color: white;
        color: @loynes-primary;
        border: 2px solid @loynes-primary;
        padding: 10px 20px;
        &:hover{
            background-color: @loynes-primary;
            color: white;
            transition: all .2s;
        }
    }
}

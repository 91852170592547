#caret-down() {
	border-top: @caret-width solid;
	border-right: @caret-width solid transparent;
	border-bottom: 0;
	border-left: @caret-width solid transparent;
}

#caret-up() {
	border-top: 0;
	border-right: @caret-width solid transparent;
	border-bottom: @caret-width solid;
	border-left: @caret-width solid transparent;
}

#caret-right() {
	border-top: @caret-width solid transparent;
	border-right: 0;
	border-bottom: @caret-width solid transparent;
	border-left: @caret-width solid;
}

#caret-left() {
	border-top: @caret-width solid transparent;
	border-right: @caret-width solid;
	border-bottom: @caret-width solid transparent;
}

#caret(@direction: down) {
	& when (@enable-caret) {
		&::after {
			display: inline-block;
			margin-left: @caret-spacing;
			vertical-align: @caret-vertical-align;
			content: "";
			& when (@direction = down) {
				#caret-down();
			}
			& when (@direction = up) {
				#caret-up();
			}
			& when (@direction = right) {
				#caret-right();
			}
		}

		& when (@direction = left) {
			&::after {
				display: none;
			}

			&::before {
				display: inline-block;
				margin-right: @caret-spacing;
				vertical-align: @caret-vertical-align;
				content: "";
				#caret-left();
			}
		}

		&:empty::after {
			margin-left: 0;
		}
	}
}

/* Padding */

.p-0 {
    padding: 0px !important;
}

.p-5 {
    padding: 5px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-25 {
    padding: 25px !important;
}

.p-50 {
    padding: 50px !important;
}

.p-100 {
    padding: 100px !important;
}

/* Padding Top */

.pt-0 {
    padding-top: 0px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

/* Padding Right */

.pr-0 {
    padding-right: 0px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-25 {
    padding-right: 25px !important;
}

.pr-50 {
    padding-right: 50px !important;
}

.pr-100 {
    padding-right: 100px !important;
}

/* Padding Bottom */

.pb-0 {
    padding-bottom: 0px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pb-100 {
    padding-bottom: 100px !important;
}

/* Padding Left */

.pl-0 {
    padding-left: 0px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pl-50 {
    padding-left: 50px !important;
}

.pl-100 {
    padding-left: 100px !important;
}

/* Margin */

.m-0 {
    margin: 0 !important;
}

.m-5 {
    margin: 5px !important;
}

.m-10 {
    margin: 10px !important;
}

.m-25 {
    margin: 25px !important;
}

.m-50 {
    margin: 50px !important;
}

.m-100 {
    margin: 100px !important;
}

/* Margin Top */

.mt-0 {
    margin-top: 0 !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-100 {
    margin-top: 100px !important;
}

/* Margin Right */

.mr-0 {
    margin-right: 0 !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mr-100 {
    margin-right: 100px !important;
}

/* Margin Bottom */

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

/* Margin Left */

.ml-0 {
    margin-left: 0 !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.ml-50 {
    margin-left: 50px !important;
}

.ml-100 {
    margin-left: 100px !important;
}

/* Margin Auto */

.mr-auto {
    margin-right: auto !important;
}

.ml-auto {
    margin-left: auto !important;
}

.m-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

/* Floats */

.float-right {
    float: right;
}

.float-left {
    float: left;
}

/* Text Alignment */

.align-center {
    text-align: center !important;
}

.align-left {
    text-align: left !important;
}

.align-right {
    text-align: right !important;
}

/* Typography */

.f-12 {
    font-size: 12px !important;
}

.f-15 {
    font-size: 15px !important;
}

.f-18 {
    font-size: 18px !important;
}

.f-24 {
    font-size: 24px !important;
}

.f-30 {
    font-size: 30px !important;
}

.f-45 {
    font-size: 45px !important;
}

.f-60 {
    font-size: 60px !important;
}

.f-bold {
    font-weight: bold !important;
}

.f-italic {
    font-style: italic !important;
}

.f-overline {
    text-decoration: overline !important;
}

.f-underline {
    text-decoration: underline !important;
}

.f-linethrough {
    text-decoration: line-through !important;
}

.f-capitalize {
    text-transform: capitalize !important;
}

.f-lowercase {
    text-transform: lowercase !important;
}

.f-uppercase {
    text-transform: uppercase !important;
}

/* Opacity */

.op-0 {
    opacity: 0 !important;
}

.op-10 {
    opacity: 0.1 !important;
}

.op-20 {
    opacity: 0.2 !important;
}

.op-30 {
    opacity: 0.3 !important;
}

.op-40 {
    opacity: 0.4 !important;
}

.op-50 {
    opacity: 0.5 !important;
}

.op-60 {
    opacity: 0.6 !important;
}

.op-70 {
    opacity: 0.7 !important;
}

.op-80 {
    opacity: 0.8 !important;
}

.op-90 {
    opacity: 0.9 !important;
}

.op-100 {
    opacity: 1 !important;
}

/* Image */

.img-fluid {
    max-width: 100% !important;
    height: auto !important;
}

/* Video */

.vid-container {
    max-width: 100%;
    margin: auto;
    padding: 25px;
}

.video-wrapper{
    margin: 15px 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-wrapper iframe{
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

/* Sizing */

.h-50 {
    height: 50px !important;
}

.h-100 {
    height: 100px !important;
}

.h-250 {
    height: 250px !important;
}

.h-500 {
    height: 500px !important;
}

.h-1000 {
    height: 1000px !important;
}

.w-50 {
    width: 50px !important;
}

.w-100 {
    width: 100px !important;
}

.w-250 {
    width: 250px !important;
}

.w-500 {
    width: 500px !important;
}

.w-1000 {
    width: 1000px !important;
}

.w-quarter {
    width: 25% !important;
}

.w-half {
    width: 50% !important;
}

.w-full {
    width: 100% !important;
}

.w-max {
    max-width: 100% !important;
}

/* Display */

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

/* Position */

.pos-static {
    position: static !important;
}

.pos-sticky {
    position: sticky !important;
}

.pos-fixed {
    position: fixed !important;
}

.pos-absolute {
    position: absolute !important;
}

.pos-relative {
    position: relative !important;
}

// /* Scroll Bar */
@media(min-width: 1200px){
    ::-webkit-scrollbar {
        width: 10px !important;
        height: 5px !important;
    }
    
    ::-webkit-scrollbar-track {
        background: #F5F5F5 !important;
    }
    
    ::-webkit-scrollbar-thumb {
        background: #D5D5D5 !important;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: #B5B5B5 !important;
    }
}

.shadow-1 {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.shadow-2 {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.shadow-3 {
    box-shadow: rgba(0,0,0,.175)!important;
}

.z-1 {
    z-index: 1 !important;
}

.z-2 {
    z-index: 2 !important;
}

.z-3 {
    z-index: 3 !important;
}

.z-4 {
    z-index: 4 !important;
}

.z-5 {
    z-index: 5 !important;
}

.z-6 {
    z-index: 6 !important;
}

.z-7 {
    z-index: 7 !important;
}

.z-8 {
    z-index: 8 !important;
}

.z-9 {
    z-index: 9 !important;
}